const title = '导购立屏'

const config = {
    development: {
        // baseUrl: 'http://10001.zhsn.com:5566/admin'
        baseUrl: 'https://10000.zcondigit.com/admin'
    },
    production: {
        baseUrl: 'https://10000.zcondigit.com/admin'
    }
}
const env = process.env

const baseUrl = config[env.NODE_ENV].baseUrl


export {
    title,
    baseUrl
}
