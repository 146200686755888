// 引入 axios
import axios from 'axios'
import { baseUrl } from '@/config'
import { isNotNull } from '@/utils/index'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({
    showSpinner: false
})

axios.defaults.baseURL = baseUrl

// 超时时间
axios.defaults.timeout = 10000

// 请求时的拦截
axios.interceptors.request.use(conf => {
    NProgress.start() // start progress bar

    // get参数编码
    let url = conf.url
    if (conf.method === 'get' && conf.params) {
        url += '?'
        const keys = Object.keys(conf.params)
        for (const key of keys) {
            if (isNotNull(conf.params[key])) {
                url += `${key}=${encodeURIComponent(conf.params[key])}&`
            }
        }
        url = url.substring(0, url.length - 1)
        conf.params = {}
    }
    conf.url = url

    return conf
}, function (error) {
    return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
        NProgress.done()
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
// 服务器状态码不是200的情况
    error => {
        NProgress.done()
        if (!error.response) {
            // todo 请求异常
        }
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    // todo
                    break;
                default:
                    // todo
            }
        }
        return Promise.reject(error.response)
    }
)

export function get (url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function post (url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function request (config) {
    return new Promise((resolve, reject) => {
        axios.request(config).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export default axios
